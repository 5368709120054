/* eslint-disable no-underscore-dangle */
import Vue from 'vue/dist/vue.common';
import Breakpoint from '../utils/_breakpoint.js';

/**
 * Tab Factory function
 */
export default function tabFactory() {
  const tabs = document.querySelectorAll('.vue-tabs');
  window.tabs = tabs;
  Array.from(tabs).forEach((tab) => {
    tab.__app = new Vue({
      el: tab,
      delimiters: ['[[', ']]'],
      data() {
        return {
          total: null,
          current: null,
          isReset: false,
          allowedBreakpoints: null,
          timer: null,
        };
      },
      mounted() {
        this.total =
          this.$el.dataset.total && Number.parseInt(this.$el.dataset.total, 10)
            ? Number.parseInt(this.$el.dataset.total, 10)
            : 0;

        this.hashSwitch = this.hashSwitch.bind(this);
        window.addEventListener('hashchange', this.hashSwitch);
        this.hashSwitch();

        if (this.current === null) {
          this.current =
            this.$el.dataset.origin && Number.parseInt(this.$el.dataset.origin, 10)
              ? Number.parseInt(this.$el.dataset.origin, 10)
              : 0;
        } else {
          this.$el.scrollIntoView({
            behavior: 'smooth',
          });
        }

        if (!this.$el.dataset.breakpoints) {
          this.init();
          return;
        }

        this.allowedBreakpoints = this.$el.dataset.breakpoints.split(',');
        this.resizeHandler = this.resizeHandler.bind(this);
        window.addEventListener('resize', this.resizeHandler);
        this.resizeHandler();
      },
      methods: {
        hashSwitch() {
          if (!document.location.hash) return;

          const links = this.$el.querySelectorAll("a[href*='#']");
          const currentLink = this.$el.querySelector(`a[href='${document.location.hash}']`);

          if (currentLink) {
            const currentLinkIndex = Array.from(links).indexOf(currentLink);

            if (currentLinkIndex !== this.current) {
              this.goTo(currentLinkIndex);
            }
          }
        },
        resizeHandler() {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            if (this.allowedBreakpoints.includes(Breakpoint.getBreakpoint())) {
              this.init();
            } else {
              this.reset();
            }
          }, 100);
        },
        clickHandler(index, hash) {
          this.goTo(index);
          this.$el.scrollIntoView({
            behavior: 'smooth',
          });

          if (!hash) return;
          if (hash.startsWith('#')) {
            document.location.hash = hash;
          } else {
            document.location = hash;
          }
        },
        goTo(index) {
          if (this.isReset) {
            return;
          }

          if (index > this.total - 1) {
            this.current = 0;
          } else if (index < 0) {
            this.current = this.total - 1;
          } else {
            this.current = index;
          }
        },
        init() {
          if (!this.isReset) {
            return;
          }

          this.current =
            this.$el.dataset.origin && Number.parseInt(this.$el.dataset.origin, 10)
              ? Number.parseInt(this.$el.dataset.origin, 10)
              : 0;

          this.isReset = false;
        },
        reset() {
          if (this.isReset) {
            return;
          }

          this.current = null;
          this.isReset = true;
        },
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
      },
    });
  });
}
