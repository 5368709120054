/**
 * Scroll to anchor Class
 */
export default class ScrollTo {
  /**
   * Constructor
   * @param {string} selector
   */
  constructor(selector = 'a[href*="#"]') {
    this.selector = selector;
    this.clickHandler = this.clickHandler.bind(this);
  }

  /**
   * Click Handler
   * @param {Event} e
   */
  clickHandler(e) {
    e.preventDefault();
    e.currentTarget.scrollTarget.scrollIntoView({
      behavior: 'smooth',
    });
  }

  /**
   * Init
   */
  init() {
    this.elements = document.querySelectorAll(this.selector);
    if (!this.elements) return;

    Array.from(this.elements).forEach((element) => {
      if (!element.hash) return;

      element.scrollTarget = document.querySelector(element.hash);
      if (!element.scrollTarget) return;

      element.addEventListener('click', this.clickHandler);
    });
  }

  /**
   * Destroy
   */
  destroy() {
    Array.from(this.elements).forEach((element) => {
      if (!element.hash) return;

      element.scrollTarget = document.querySelector(element.hash);
      if (!element.scrollTarget) return;

      element.removeEventListener('click', this.clickHandler);
    });
  }
}
