import Vue from 'vue/dist/vue.common';

Vue.config.devtools = true;

/**
 * Accordeon Factory function
 */
export default function accordeonFactory() {
  const accordeons = document.querySelectorAll('.vue-accordeon');
  window.accordeons = accordeons;
  Array.from(accordeons).forEach((accordeon) => {
    accordeon.__app = new Vue({
      // eslint-disable-line
      el: accordeon,
      delimiters: ['[[', ']]'],
      data() {
        return {
          current: null,
          links: [],
        };
      },
      mounted() {
        this.links = this.$el.querySelectorAll("a[href*='#']");

        if (!document.location.hash) return;

        const hash = decodeURIComponent(document.location.hash);

        const currentLink = this.$el.querySelector(`a[href='${hash}']`);

        if (!currentLink) return;
        const currentLinkIndex = Array.from(this.links).indexOf(currentLink);
        this.clickHandler(currentLinkIndex);
      },
      methods: {
        clickHandler(index) {
          this.current = index !== this.current ? index : null;

          if (this.current && this.links.length > 0 && this.links[this.current]) {
            this.$nextTick(() => {
              this.links[this.current].scrollIntoView({
                behavior: 'smooth',
              });
            });
          }
        },
        recruitementHandler(value, formSelector, inputSelector) {
          if (!value || !formSelector || !inputSelector) return;

          const form = document.querySelector(formSelector);
          if (!form) return;
          form.scrollIntoView({
            behavior: 'smooth',
          });

          const input = form.querySelector(inputSelector);
          if (!input) return;
          input.value = value;
        },
      },
    });
  });
}
