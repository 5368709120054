import { useResize } from '@studiometa/js-toolkit';

/**
 * Class Breakpoint
 */
export default class Breakpoint {
  /**
   * Get the current breakpoint
   * @returns {void}
   */
  static getBreakpoint() {
    return useResize().props().breakpoint;
  }
}
