import { Base } from '@studiometa/js-toolkit';

/**
 * Popin class.
 * @property {boolean} isOpen
 * @property {Array<HTMLElement>} toggleTriggers
 */
class Popin extends Base {
  /**
   * Class config.
   */
  static config = {
    name: 'Popin',
    options: {
      styles: {
        type: Object,
        default: () => ({
          root: {
            open: 'z-above-popin',
            closed: 'z-popin pointer-events-none',
          },
          content: {
            open: '',
            closed: 'translate-y-full-popin s:translate-y-0 s:translate-x-full-popin',
          },
          overlay: {
            open: '',
            closed: 'opacity-0',
          },
        }),
      },
    },
    refs: ['content', 'overlay', 'closeBtn'],
  };

  /**
   * Is the popin open?
   * @type {boolean}
   */
  isOpen = false;

  /**
   * The triggers from the document which will toggle the popin.
   * @type {Array<HTMLElement>}
   */
  toggleTriggers = [];

  /**
   * Bind toggleTriggers to the toggle method on mount.
   */
  mounted() {
    this.isOpen = false;
    this.toggleTriggers = Array.from(
      document.querySelectorAll(`a[href='#${this.$el.id}'].js-popin-toggle`),
    );

    this.toggleTriggers.forEach((toggle) => {
      toggle.addEventListener('click', this.onToggleTriggersClick.bind(this));
    });
  }

  /**
   * Unbind the toggle method from the toggleTriggers on destroy.
   */
  destroyed() {
    this.toggleTriggers.forEach((toggle) => {
      toggle.removeEventListener('click', this.onToggleTriggersClick);
    });
  }

  /**
   * Close when clicking on the overlay ref.
   * @param {MouseEvent} event The event object.
   */
  onOverlayClick(event) {
    event.preventDefault();
    this.close();
  }

  /**
   * Close when clicking on the closeBtn ref.
   * @param {MouseEvent} event The event object.
   */
  onCloseBtnClick(event) {
    event.preventDefault();
    this.close();
  }

  /**
   * Toggle when clicking on the trigger ref.
   * @param {MouseEvent} event The event object.
   */
  onToggleTriggersClick(event) {
    event.preventDefault();
    this.toggle();
  }

  /**
   * Toggle the state of the popin.
   */
  toggle() {
    const method = this.isOpen ? 'close' : 'open';
    this[method]();
  }

  /**
   * Open the popin.
   */
  open() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.updateStyles('open');
    this.$emit('open');
  }

  /**
   * Close the popin.
   */
  close() {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
    this.updateStyles('closed');
    this.$emit('close');
  }

  /**
   * Update the style on each ref based on the options and the given state.
   * @param {string} state The popin state: `open` or `closed`.
   */
  updateStyles(state) {
    Object.entries(this.$options.styles).forEach(([refName, styles]) => {
      const ref = refName === 'root' ? this.$el : this.$refs[refName];

      if (!ref) {
        return;
      }

      const classesToAdd = state === 'open' ? styles.open : styles.closed;
      const classesToRemove = state === 'open' ? styles.closed : styles.open;

      if (classesToAdd.trim()) {
        ref.classList.add(...classesToAdd.split(' '));
      }

      if (classesToRemove.trim()) {
        ref.classList.remove(...classesToRemove.split(' '));
      }
    });
  }
}

/**
 * Popin Factory function
 * @returns {Array<Popin>} A list of all created Popin instance.
 */
export default function popinFactory() {
  const popins = Popin.$factory('.vue-popin');

  popins.forEach((popin) => {
    const otherPopins = popins.filter((p) => p.$id !== popin.$id);
    popin.$on('open', () => otherPopins.forEach((p) => p.close()));
  });

  return popins;
}
