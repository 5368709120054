import Flickity from 'flickity';
import { Base } from '@studiometa/js-toolkit';

/**
 * Slider images class
 */
export default class HotspotSlider extends Base {
  /**
   * Config
   * @readonly
   * @memberof HotspotSlider
   */
  static config = {
    name: 'HotspotSlider',
    refs: ['slider', 'progress', 'items[]', 'hotspot[]', 'prev', 'next'],
  };

  /**
   * Mounted
   * @memberof HotspotSlider
   */
  mounted() {
    /** @type {boolean} Is sliderAuto instance active */
    this.isInit = false;

    this.instance = null;

    this.currentIndex = 0;

    this.init();
  }

  /**
   * Init slider.
   * @returns {boolean} Is init?
   */
  init() {
    if (!this.$refs.slider) {
      return false;
    }

    this.instance = this.createInstance(this.$refs.slider);
    this.isInit = true;

    return true;
  }

  /**
   * Create the Flickity instance
   * @param   {HTMLElement} slider
   * @returns {Flickity}
   */
  createInstance(slider) {
    const instance = new Flickity(slider, {
      contain: true,
      prevNextButtons: false,
      pageDots: false,
    });

    instance.on('ready', this.handlerReady);
    instance.on('change', this.handlerChange);
    instance.on('staticClick', this.handlerClick);

    return instance;
  }

  /**
   * On next trigger.
   */
  onPrevClick() {
    this.instance.previous(true);
  }

  /**
   * On previous trigger.
   */
  onNextClick() {
    this.instance.next(true);
  }

  /**
   * Method handler when the event 'select' is fired
   * Update the hotspot content according to the active slide
   * @param {any} event
   * @param {any} pointer
   * @param {any} cellElement
   * @param {any} cellIndex
   */
  handlerClick(event, pointer, cellElement, cellIndex) {
    this.instance.select(cellIndex);
  }

  /**
   * Handler for the Flickity `ready` event.
   * @see https://flickity.metafizzy.co/events.html#ready
   */
  handlerReady() {
    this.updateProgess();
  }

  /**
   * Handler for the Flickity `change` event.
   * @see https://flickity.metafizzy.co/events.html#change
   * @param {number} index The index of the new active cell.
   */
  handlerChange(index) {
    this.currentIndex = index;
    this.updateContent();
    this.updateProgess();
  }

  /**
   * Update the progress UI element.
   */
  updateProgess() {
    if (this.$refs.progress) {
      const progress = (this.currentIndex + 1) / this.$refs.items.length;
      this.$refs.progress.firstElementChild.style.transform = `scaleX(${progress})`;
    }
  }

  /**
   * Update the hotspot content according to the active slide.
   */
  updateContent() {
    this.$refs.hotspot.forEach((hotspot, index) => {
      if (index === this.currentIndex) return;
      hotspot.classList.add('hidden');
    });

    const activeElement = this.$refs.hotspot[this.currentIndex];

    activeElement.classList.remove('hidden');
  }

  /**
   * Destroy the Flickity instance.
   * @returns {boolean} Is destroy?
   */
  destroyed() {
    if (!(this.instance && typeof this.instance.destroy === 'function')) {
      return false;
    }

    // Destroy Flickity instance
    this.instance.destroy();
    this.isInit = false;
    return true;
  }
}
