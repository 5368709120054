/**
 * File inputs class
 * Manage file inputs
 */
export default class FileInputs {
  /**
   * Constructor
   * @param {string} containerSelector Container selector
   * @param {string} inputSelector Input selector
   * @param {string} infosSelector Infos selector
   */
  constructor(
    containerSelector = '.js-file-input',
    inputSelector = 'input[type=file]',
    infosSelector = '.js-file-input-infos',
  ) {
    this.selector = {
      container: containerSelector,
      input: inputSelector,
      infos: infosSelector,
    };

    this.inputs = [];
  }

  /**
   * Click Handler
   * @param {Event} e Click event
   */
  clickHandler({ target }) {
    // eslint-disable-next-line prefer-destructuring
    target.fileInputData.infos.innerHTML = target.value.split('\\').at(-1);
  }

  /**
   * Init
   */
  init() {
    const containers = document.querySelectorAll(this.selector.container);
    if (!containers) return;

    Array.from(containers).forEach((container) => {
      const input = container.querySelector(this.selector.input);
      if (!input) return;

      const infos = container.querySelector(this.selector.infos);
      if (!infos) return;

      input.fileInputData = {
        container,
        infos,
      };

      input.addEventListener('change', this.clickHandler);

      this.inputs.push(input);
    });
  }

  /**
   * Destroy
   */
  destroy() {
    this.inputs.forEach((input) => {
      input.removeEventListener('click', this.clickHandler);
    });

    this.inputs = [];
  }
}
