/* eslint-disable no-underscore-dangle */
import Vue from 'vue/dist/vue.common';

/**
 * Create array of number.
 * @param   {number}   total Length of the array.
 * @returns {number[]}       An array of number.
 */
function createArrayOfNumber(total = 10) {
  // eslint-disable-next-line unicorn/no-useless-spread
  return [...new Array(total)].map((val, index) => index / total);
}

/**
 * Sticky Nav Factory function
 */
export default function stickyNavFactory() {
  const stickyNavs = document.querySelectorAll('.vue-sticky-nav');
  window.stickyNavs = stickyNavs;
  Array.from(stickyNavs).forEach((stickyNav) => {
    stickyNav.__app = new Vue({
      el: stickyNav,
      delimiters: ['[[', ']]'],
      data() {
        return {
          current: 0,
          sections: [],
          intersections: {},
          bestActiveKey: null,
        };
      },
      mounted() {
        const sections = document.querySelectorAll('section[data-product-section]');
        const options = { threshold: createArrayOfNumber(100) };
        this.sections = Array.from(sections).map((section) => {
          // eslint-disable-next-line no-undef
          const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
              this.add(section.id, entry.intersectionRatio);
            } else {
              this.remove(section.id);
            }
          }, options);

          observer.observe(section);

          return {
            el: section,
            observer,
            id: section.id,
            title: decodeURIComponent(section.dataset.productSection),
          };
        });
      },
      methods: {
        updateCurrent() {
          const sortedIntersections = Object.entries(this.intersections).sort((a, b) => {
            if (a[1] < b[1]) {
              return -1;
            }

            if (a[1] > b[1]) {
              return 1;
            }

            return 0;
          });

          if (sortedIntersections.length === 0) {
            return;
          }
          const [key] = sortedIntersections.pop() || [];
          this.current = key;
        },

        add(key, ratio) {
          this.intersections[key] = ratio;
          this.updateCurrent();
        },

        remove(key) {
          delete this.intersections[key];
          this.updateCurrent();
        },

        scrollTo(index) {
          this.sections
            .find((section) => section.id === index)
            .el.scrollIntoView({
              behavior: 'smooth',
            });
        },
      },
    });
  });
}
