import Flickity from 'flickity-as-nav-for';
import Breakpoint from '../utils/_breakpoint.js';

/**
 * Slider class
 */
export default class Slider {
  /**
   * Constructor
   */
  constructor() {
    /** @type {Array} The active breakpoints */
    this.allowedBreakpoints = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];

    /** @type {Array} The mobile behaviour breakpoints */
    this.mobileBreakpoints = ['xxs', 'xs'];

    /** @type {boolean} Is sliderAuto instance active */
    this.isInit = false;

    this.slidersWrapper = [];
    this.instances = [];
    this.currentIndex = 0;

    this.loadHandler = this.loadHandler.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.getOptions = this.getOptions.bind(this);

    window.addEventListener('load', this.loadHandler);
    window.addEventListener('resize', this.resizeHandler);
  }

  /**
   * Get Flickity options.
   * @param  {object} instance Instance.
   * @returns {object}          Configuration.
   */
  getOptions(instance) {
    return {
      accessibility: true,
      autoPlay: false,
      cellSelector: '.js-slider-item',
      prevNextButtons: false,
      pageDots: false,
      resize: true,
      wrapAround: false,
      on: {
        ready: () => {
          const children = instance.slider.querySelectorAll('.js-slider-item');
          const currentPercent = (this.currentIndex + 1) / children.length;
          const currentNode = children[this.currentIndex];

          if (instance.progress) {
            instance.progress.firstElementChild.style.transform = `scaleX(${currentPercent})`;
          }

          if (currentNode.dataset.sliderUrl && instance.seeMore) {
            instance.seeMore.href = currentNode.dataset.sliderUrl;
          }
        },
        change: (index) => {
          this.currentIndex = index;
          const currentPercent = (this.currentIndex + 1) / instance.flickity.slides.length;

          if (instance.progress) {
            instance.progress.firstElementChild.style.transform = `scaleX(${currentPercent})`;
          }

          if (instance.flickity.selectedElement.dataset.sliderUrl && instance.seeMore) {
            instance.seeMore.href = instance.flickity.selectedElement.dataset.sliderUrl;
          }
        },
      },
    };
  }

  /**
   * On previous trigger.
   * @param  {object} instance Instance.
   * @returns {void}            Flickity previous function.
   */
  static onPrev(instance) {
    return instance.flickity.previous(true);
  }

  /**
   * On next trigger.
   * @param  {object} instance Instance.
   * @returns {void}            Flickity next function.
   */
  static onNext(instance) {
    return instance.flickity.next(true);
  }

  /**
   * Init slider.
   * @returns {boolean} Is init?
   */
  init() {
    if (this.slidersWrapper.length === 0) {
      return false;
    }

    // Initialize sliders
    for (let j = 0; j < this.slidersWrapper.length; j += 1) {
      this.instances[j] = {};

      const slider = this.slidersWrapper[j].querySelector('.js-slider');

      if (slider === null) {
        /* eslint-disable-next-line no-continue */
        continue;
      }

      // Store DOM Element in instance object.
      const options = Object.assign(this.getOptions(this.instances[j]), {
        cellAlign:
          this.slidersWrapper[j].dataset.cellAlign === 'undefined'
            ? 'center'
            : this.slidersWrapper[j].dataset.cellAlign,
        draggable: !!this.mobileBreakpoints.includes(Breakpoint.getBreakpoint()),
      });
      const sliderPrev = this.slidersWrapper[j].querySelector('.js-slider-prev');
      const sliderNext = this.slidersWrapper[j].querySelector('.js-slider-next');
      const sliderProgress = this.slidersWrapper[j].querySelector('.js-slider-progress');
      const backgroundSlider = this.slidersWrapper[j].querySelector('.js-slider-background-slider');
      const seeMoreButton = this.slidersWrapper[j].querySelector('.js-slider-seemore');

      this.instances[j].wrapper = this.slidersWrapper[j];
      this.instances[j].slider = slider;
      this.instances[j].prev = sliderPrev;
      this.instances[j].next = sliderNext;
      this.instances[j].progress = sliderProgress;
      this.instances[j].seeMore = seeMoreButton;

      let autoPlay = false;

      if (this.instances[j].wrapper.dataset.sliderAutoplay) {
        autoPlay = 6000;
      }

      // Init main slider.
      this.instances[j].flickity = new Flickity(slider, {
        ...options,
        autoPlay,
      });

      // Init background slider.
      if (backgroundSlider !== null) {
        this.instances[j].flickityBackgroundSlider = new Flickity(
          backgroundSlider,
          Object.assign(options, {
            asNavFor: this.instances[j].flickity.element,
          }),
        );
      }

      // Init prev button.
      if (this.instances[j].prev !== undefined) {
        this.instances[j].prev.addEventListener(
          'click',
          Slider.onPrev.bind(null, this.instances[j]),
        );
      }

      // Init next button.
      if (this.instances[j].next !== undefined) {
        this.instances[j].next.addEventListener(
          'click',
          Slider.onNext.bind(null, this.instances[j]),
        );
      }
    }

    return true;
  }

  /**
   * Is destroy.
   * @returns {boolean} Is destroy?
   */
  destroy() {
    if (this.instances.length === 0) {
      return false;
    }

    // Initialize sliders
    for (let j = 0; j < this.instances.length; j += 1) {
      if (this.instances[j].prev !== undefined) {
        this.instances[j].prev.removeEventListener(
          'click',
          // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
          Slider.onPrev.bind(null, this.instances[j]),
        );
      }

      if (this.instances[j].next !== undefined) {
        this.instances[j].next.removeEventListener(
          'click',
          // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
          Slider.onNext.bind(null, this.instances[j]),
        );
      }

      if (this.instances[j].backgroundSlider !== undefined) {
        this.instances[j].backgroundSlider.destroy();
      }

      this.instances[j].flickity.destroy();
    }

    this.instances = [];

    return true;
  }

  /**
   * Resize handler.
   * @returns {boolean} Need to init?
   */
  resizeHandler() {
    let initialize = true;

    if (this.allowedBreakpoints.includes(Breakpoint.getBreakpoint()) === false) {
      initialize = false;
    }

    if (initialize && !this.isInit) {
      this.init();
      this.isInit = true;
    }

    if (!initialize && this.isInit) {
      this.destroy();
      this.isInit = false;
    }

    for (let i = 0; i < this.instances.length; i += 1) {
      this.instances[i].flickity.reloadCells();

      if (this.instances[i].flickityBackgroundSlider) {
        this.instances[i].flickityBackgroundSlider.reloadCells();
      }
    }

    return initialize;
  }

  /**
   * Load handler.
   * @returns {void} Resize handler.
   */
  loadHandler() {
    this.slidersWrapper = document.querySelectorAll('.js-slider-wrapper');

    return this.resizeHandler();
  }
}
