import { Base, createApp, withBreakpointObserver } from '@studiometa/js-toolkit';
import { nextFrame } from '@studiometa/js-toolkit/utils';
import { Frame, Panel } from '@studiometa/ui';
import accordeonFactory from './components/_accordeon.js';
import popinFactory from './components/_popin.js';
import tabFactory from './components/_tabs.js';
import stickyNavFactory from './components/_sticky-product-nav.js';
import Menu from './components/_menu.js';
import Header from './components/_header.js';
import Slider from './components/_slider.js';
import HotspotSlider from './components/_hotspot-slider.js';
import ScrollTo from './components/_scroll-to.js';
import FileInputs from './components/_file-input.js';

/**
 * Class App
 *
 * Manage all components
 */
class App extends Base {
  /**
   * Config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'App',
    components: {
      Frame,
      Panel: withBreakpointObserver(Panel),
    },
  };

  /**
   * Mounted hook.
   */
  mounted() {
    this.isReady = true;

    this.menu = new Menu();
    this.header = new Header();
    this.slider = new Slider();
    this.scrollTo = new ScrollTo('.js-scroll-to');
    this.fileInputs = new FileInputs();
    this.hotspotSliders = HotspotSlider.$factory('HotspotSlider');

    this.menu.init();
    this.header.init();
    this.slider.init();

    window.addEventListener('load', () => {
      document.documentElement.classList.remove('no-js');
      this.isLoaded = true;

      accordeonFactory();
      popinFactory();
      tabFactory();
      stickyNavFactory();

      this.scrollTo.init();
      this.fileInputs.init();

      this.initCaptcha();

      return this.isLoaded;
    });
  }

  /**
   * Init Captcha
   */
  initCaptcha() {
    nextFrame().then(() => {
      nextFrame(() => {
        if (window.grecaptcha) {
          const el = document.querySelector('.ginput_recaptcha');
          if (!el) {
            return;
          }
          window.grecaptcha.render(el, {
            sitekey: el.dataset.sitekey,
          });
        }
      });
    });
  }
}

export default createApp(App);
