import Breakpoint from '../utils/_breakpoint.js';

/**
 * Header class
 */
export default class Header {
  /**
   * Constructor
   */
  constructor() {
    /** @type {Array} The active breakpoints */
    this.allowedBreakpoints = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];

    /** @type {Array} The mobile behaviour breakpoints */
    this.mobileBreakpoints = ['xxs', 'xs'];

    /** @type {boolean} Is sliderAuto instance active */
    this.isInit = false;

    this.item = null;

    this.loadHandler = this.loadHandler.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);

    window.addEventListener('load', this.loadHandler);
    window.addEventListener('resize', this.resizeHandler);
  }

  /**
   * setScrollTopClasses
   * @returns {void}
   */
  static setScrollTopClasses() {
    if (window.scrollY === 0) {
      return document.body.classList.add('is-scroll-top');
    }

    return document.body.classList.remove('is-scroll-top');
  }

  /**
   * scrollHandler
   */
  static scrollHandler() {
    Header.setScrollTopClasses();
  }

  /**
   * Init header.
   * @returns {boolean} Is init?
   */
  init() {
    if (this.item === null) {
      return false;
    }

    // Initialize header scroll
    Header.setScrollTopClasses();
    window.addEventListener('scroll', Header.scrollHandler);

    return true;
  }

  /**
   * Is destroy.
   * @returns {boolean} Is destroy?
   */
  destroy() {
    if (this.item === null) {
      return false;
    }

    // Initialize header scroll
    window.removeEventListener('scroll', Header.scrollHandler);

    return true;
  }

  /**
   * Resize handler.
   * @returns {boolean} Need to init?
   */
  resizeHandler() {
    let initialize = true;

    if (this.allowedBreakpoints.includes(Breakpoint.getBreakpoint()) === false) {
      initialize = false;
    }

    if (initialize && !this.isInit) {
      this.init();
    }

    if (!initialize && this.isInit) {
      this.destroy();
    }

    return initialize;
  }

  /**
   * Load handler.
   * @returns {void} Resize handler.
   */
  loadHandler() {
    this.item = document.querySelector('.js-header');

    return this.resizeHandler();
  }
}
